







































































































































































































































































































import keyBy from 'lodash/keyBy';
import Component, { mixins } from 'vue-class-component';
import FilterMixin from '@/core/mixins/filter.mixin';
import DataTableMixin from '@/core/mixins/data-table.mixin';
import CreateCoupon from './components/CreateCoupon.vue';
import CreatePromocode from './components/CreatePromocode.vue';
import CreateCampaign from './components/CreateCampaign.vue';
import CouponsFilters from '@/views/Admin/components/CouponsFilters.vue';
import _ from 'lodash';

function defaultFilter(): SimpleObject {
  return {
    activeFrom: '',
    activeTo: '',
    campaignID: [],
    email: '',
    phone: '',
    countFrom: '',
    countTo: '',
  };
}

@Component
export default class AdminCoupons extends mixins(FilterMixin, DataTableMixin) {
  campaignsById: { [key: string]: AdminCampaignItem } = {};
  filter: any = defaultFilter();
  activeTab: string = 'manage';
  tariffs: any = null;
  // headers: TableHeaderItem[] = [
  //   {
  //     text: 'Пользователь',
  //   },
  //   {
  //     text: 'Тип купона',
  //   },
  //   {
  //     text: 'Количество',
  //     width: '120px',
  //   },
  //   {
  //     text: 'Дата создания',
  //   },
  // ];
  campaigns: AdminCampaignItem[] = [];

  async created() {
    this.tariffs = await this.$api.tariff.Items();
    await Promise.all([ this.fetchData(), this.getCampaignsList() ]);
  }

  get headers(): TableHeaderItem[] {
    if (this.activeTab === 'manage') {
      return [
        {
          text: 'Пользователь',
        },
        {
          text: 'Тип купона',
        },
        {
          text: 'Количество',
          width: '120px',
        },
        {
          text: 'Дата создания',
        },
      ];
    } else {
      return [
        {
          text: 'Название',
        },
        {
          text: 'Активен',
        },
        {
          text: 'Инфо',
        },
        {
          text: 'Действия',
        },
      ];
    }
  }

  handleRequest(offset: number, limit: number) {
    const filter = {
      ...this.filter,
      campaignID: _.map(this.filter.campaignID, 'ID'),
    };
    if (this.activeTab === 'manage') {
      return this.$api.admin.GetCoupons({
        offset,
        limit,
        ...filter,
      });
    } else if (this.activeTab === 'create') {
      return this.$api.admin.GetCampaigns({
        offset,
        limit,
      });
    } else if (this.activeTab === 'promo') {
      return this.$api.admin.GetPromocodes({
        offset,
        limit,
      });
    }
  }

  getTariffById(id: string) {
    return this.tariffs.tariffs.find((elem: any) => {
      return elem.tariff_id === id;
    });
  }

  getTariffName(id: string) {
    const tariff = this.getTariffById(id);
    return tariff ? tariff.tariff_name : 'Тариф не найден';
  }

  changeTab(value: string) {
    this.activeTab = value;
    this.fetchData();
  }

  async getCampaignsList() {
    this.campaigns = await this.$api.admin.GetCampaigns<any>();
    this.campaignsById = keyBy(this.campaigns, 'ID');
  }

  campaign(campaignID: string, key: keyof AdminCampaignItem): any {
    if (this.campaignsById[campaignID]) {
      return this.campaignsById[campaignID][key];
    }

    return null;
  }

  openCreateCoupon() {
    this.$dialog.open({
      component: CreateCoupon,
      props: {
        campaignsById: this.campaignsById,
        onRequestEnd: this.fetchData,
      },
    });
  }

  openCreateCampaign(campaign: any = null) {
    this.$dialog.open({
      component: CreateCampaign,
      props: {
        campaign,
        onRequestEnd: this.fetchData,
      },
    });
  }

  async deleteCampaign(id: string) {
    if (!confirm('Вы уверены, что хотите удалить эту кампанию?')) {
      return;
    }
    await this.$api.admin.DeleteCampaign<any>({ campaign_id: id });
    this.fetchData();
  }

  async deletePromocode(id: string) {
    if (!confirm('Вы уверены, что хотите удалить этот промокод?')) {
      return;
    }
    await this.$api.admin.DeletePromocode<any>({ promocode_campaign_id: id });
    this.fetchData();
  }

  openCreatePromocode(promocode: any = null) {
    this.$dialog.open({
      component: CreatePromocode,
      props: {
        promocode,
        tariffs: this.tariffs.tariffs,
        coupons: this.campaigns,
        onRequestEnd: this.fetchData,
      },
    });
  }

  openFilter() {
    this.$dialog.open({
      component: CouponsFilters,
      props: {
        defaultFilter: defaultFilter,
        campaigns: this.campaigns,
        filter: this.filter,
        submit: () => {
          this.$dialog.close();
          const filter = {
            ...this.filter,
            campaignID: this.filter.campaignID.map((item: any) => item.ID),
          };

          this.applyFilter(filter);
        },
      },
    });
  }
}
