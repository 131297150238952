import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class HelpersMixin extends Vue {
  // eslint-disable-next-line @typescript-eslint/camelcase
  $helper_classByStatus(status: string): string {
    const statusColorsMap: { [key: string]: string } = {
      requested: 'bg-secondary text-white',
      waiting_for_payment: 'bg-danger text-white',
      paid: 'bg-dark text-white',
      done: 'bg-success text-white',
      pending: 'bg-orange text-white',
      manual: 'bg-warning',
      manual_only: 'bg-warning',
      оплачен: 'bg-purple text-white',
      'в работе': 'bg-info text-white',
      'готов к оплате': 'bg-orange text-white',
      'объект аннулирован': 'bg-indigo text-white',
      'удален': 'bg-danger text-white',
      ошибка: 'bg-danger text-white',
      отмена: 'bg-pink text-white',
      завершен: 'bg-cyan text-white',
      создан: 'bg-success text-white',
      'не найден': 'bg-danger text-white',
      waiting_for_owners: 'bg-warning',
      waiting_for_execution: 'bg-info text-white',
      waiting_for_verification: 'bg-purple text-white',
      order_pending: 'bg-secondary text-white',
      order_processing: 'bg-purple text-white',
      order_complete: 'bg-info text-white',
      order_error: 'bg-danger text-white',
      download_pending: 'bg-info text-white',
      download_processing: 'bg-purple text-white',
      download_complete: 'bg-success text-white',
      download_error: 'bg-danger text-white',
      delivered: 'bg-success text-white',
      sent: 'bg-info text-white',
      opened: 'bg-pink text-white',
      clicked: 'bg-cyan text-white',
      unsubscribed: 'bg-warning text-white',
      soft_bounced: 'bg-secondary text-white',
      hard_bounced: 'bg-danger text-white',
      spam: 'bg-secondary text-white',
    };

    return statusColorsMap[(status || '').toLowerCase()];
  }

  // eslint-disable-next-line @typescript-eslint/camelcase
  $helper_textByStatus(statusText: string): string {
    const statusTextByKey: SimpleObject<string> = {
      requested: 'Заказ запрошен',
      waiting_for_payment: 'Ожидает оплаты',
      paid: 'Оплачен',
      done: 'Выполнен',
      manual: 'Ручной заказ',
      'удален': 'Удален',
      'возврат денег': 'возврат',
      pending: 'pending',
      waiting_for_owners: 'Ожидает заполнения (Risk)',
      waiting_for_execution: 'Выполняется (Risk)',
      waiting_for_verification: 'Ждет подтверждения (Risk)',
      order_pending: 'В очереди (spv)',
      order_processing: 'Выполняется (spv)',
      order_complete: 'Заказ оформлен (spv)',
      order_error: 'Ошибка при заказе (spv)',
      download_pending: 'Ожидает скачивания (spv)',
      download_processing: 'Скачивается (spv)',
      download_complete: 'Выполнен (spv)',
      download_error: 'Ошибка скачивания (spv)',
      delivered: 'Доставлено',
      sent: 'Отправлено',
      opened: 'Просмотрено',
      clicked: 'Переход по ссылке',
      unsubscribed: 'Отписался',
      soft_bounced: 'Недоставка',
      hard_bounced: 'Недоставлено(ошибка)',
      spam: 'Спам',
    };

    return statusTextByKey[statusText] || statusText;
  }

  // eslint-disable-next-line @typescript-eslint/camelcase
  $helper_nameByProduct(name: string): string {
    const productNameMap: { [key: string]: string } = {
      CheckOwnerV2: 'Проверка физического лица',
      CheckOwner: 'Проверка физического лица',
      ReportAI: 'Отчет на основании ИИ',
      Tariff: 'Тариф',
      IndependentReportAI: 'Проверка физчек-эксперт',
      AccountBalanceReplenishmentCustom: 'Пополнение баланса',
      AccountBalanceReplenishmentMini: 'Пополнение баланса',
      AccountBalanceReplenishmentNorma: 'Пополнение баланса',
      AccountBalanceReplenishmentOptima: 'Пополнение баланса',
      AccountBalanceReplenishmentRealtor: 'Пополнение баланса',
      AccountBalanceReplenishmentAgency: 'Пополнение баланса',
      CostAssessment: 'Оценка стоимости',
      CostAssessmentAdvanced: 'Оценка стоимости',
      CreditRating: 'Проверка финансовой благонадежности',
      ScoreRating: 'Проверка финансовой благонадежности',
      EgrnRightList: 'Отчет о переходе прав',
      EgrnRightListFast: 'Отчет о переходе прав',
      EgrnObject: 'Отчет об обьекте',
      EgrnObjectFast: 'Отчет об обьекте',
      RiskAssessmentV2: 'Полная проверка (отчет о рисках)',
      RiskAssessmentFastV2: 'Полная проверка (отчет о рисках) - Срочный',
      RiskAssessmentSimple: 'Проверка недвижимости - простая',
      RiskAssessmentAdvanced: 'Проверка недвижимости',
      RiskAssessmentFast: 'Полная проверка (отчет о рисках) - Срочный',
      CounterAgent: 'Проверка контрагента',
      CheckAuto: 'Проверка авто',
      CustomProduct: 'Индивидуальный продукт',

      // | 'OrderMaintenance'
      // | 'MortgageSelection'
      // | 'PropertySaleEntranceFee'
      // | 'PropertySaleEntranceFeeLight'
      // | 'SubscriptionBasic'
    };

    if (name.includes('Tariff')) {
      name = name.split(' ')[0];
    }

    if (productNameMap.hasOwnProperty(name)) {
      return productNameMap[name];
    } else {
      return '¯\\_(ツ)_/¯';
    }
  }
}
