var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "custom-select-wrapper",
      class: [
        _vm.arrow && _vm.$style["custom-select-arrow"],
        !_vm.label && _vm.$style["custom-select-labelless"]
      ]
    },
    [
      _c(
        "select",
        {
          class: [
            _vm.$style["custom-select"],
            _vm.size && _vm.$style["custom-select-" + _vm.size]
          ],
          domProps: { value: _vm.nativeInputValue },
          on: { change: _vm.onChange }
        },
        _vm._l(_vm.items, function(v, key) {
          return _c(
            "option",
            {
              key: key,
              domProps: { value: _vm.valueKey.length ? v[_vm.valueKey] : key }
            },
            [
              _vm._t(
                "default",
                [_vm._v("\n        " + _vm._s(v) + "\n      ")],
                { value: v, keyValue: key }
              )
            ],
            2
          )
        }),
        0
      ),
      _vm.label
        ? _c("label", { class: [{ active: _vm.nativeInputValue }] }, [
            _vm._v("\n    " + _vm._s(_vm.label) + "\n  ")
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }