var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "contours-table" }, [
    _vm._m(0),
    _c(
      "tbody",
      _vm._l(_vm.statements, function(item, index) {
        return _c("tr", { key: index }, [
          _c("td", [_vm._v(_vm._s(index + 1))]),
          _c("td", [
            _vm._v(
              _vm._s(
                _vm.get(
                  item,
                  "generic_record.object.common_data.cad_number",
                  "Данные отсутствуют"
                )
              )
            )
          ]),
          _c("td", [
            _vm._v(
              _vm._s(
                _vm.get(
                  item,
                  "generic_record.location_in_build.level.floor",
                  "Данные отсутствуют"
                )
              )
            )
          ]),
          _c("td", [
            _vm._v(
              _vm._s(
                _vm.get(
                  item,
                  "generic_record.params.purpose.value",
                  "Данные отсутствуют"
                )
              )
            )
          ]),
          _c("td", [
            _vm._v(
              _vm._s(
                _vm.get(
                  item,
                  "generic_record.params.permitted_uses.permitted_use.name",
                  "Данные отсутствуют"
                )
              )
            )
          ]),
          _c(
            "td",
            [
              _vm.isObject(_vm.get(item, "generic_record.params.area"))
                ? [
                    _vm.get(
                      item,
                      "generic_record.params.area.area_type.value",
                      ""
                    )
                      ? [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.get(
                                  item,
                                  "generic_record.params.area.area_type.value",
                                  ""
                                )
                              ) +
                              ":\n          "
                          )
                        ]
                      : _vm._e(),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.get(item, "generic_record.params.area.area", "")
                        ) +
                        "\n          "
                    ),
                    _vm.get(
                      item,
                      "generic_record.params.area.area_inaccuracy",
                      ""
                    )
                      ? [
                          _vm._v(
                            "\n            ± " +
                              _vm._s(
                                _vm.get(
                                  item,
                                  "generic_record.params.area.area_inaccuracy",
                                  ""
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      : _vm._e(),
                    _vm._v("\n          м"),
                    _c("sup", [_vm._v("2")])
                  ]
                : [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.get(
                            item,
                            "generic_record.params.area",
                            "Данные отсутствуют"
                          )
                        ) +
                        "\n        "
                    )
                  ]
            ],
            2
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { colspan: "7" } }, [
          _vm._v(
            "\n        Перечень помещений, машино-мест, расположенных в здании, сооружении\n      "
          )
        ])
      ]),
      _c("tr", [
        _c("th", [_vm._v("\n        N п/п\n      ")]),
        _c("th", [
          _vm._v(
            "\n        Кадастровый номер помещения,\n        машино-места\n      "
          )
        ]),
        _c("th", [_vm._v("\n        Номер этажа\n        (этажей)\n      ")]),
        _c("th", [_vm._v("\n        Назначение\n        помещения\n      ")]),
        _c("th", [
          _vm._v("\n        Вид разрешенного\n        использования\n      ")
        ]),
        _c("th", [_vm._v("\n        Площадь,\n        м2\n      ")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }