var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.result && _vm.result.length
        ? _c(
            "div",
            { staticClass: "tax" },
            _vm._l(_vm.result, function(nalog, nIndex) {
              return _c("div", { key: nIndex, staticClass: "tax-item-wrap" }, [
                _c("div", { staticClass: "tax-item" }, [
                  nalog.info
                    ? _c("div", { staticClass: "tax-item__type" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(nalog.info.penaltyName) +
                            "\n        "
                        )
                      ])
                    : nalog.billName
                    ? _c("div", { staticClass: "tax-item__type" }, [
                        _vm._v(
                          "\n          " + _vm._s(nalog.billName) + "\n        "
                        )
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "tax-item__value" }, [
                    _vm._v(
                      "\n          " + _vm._s(nalog.amount) + " ₽\n        "
                    )
                  ])
                ])
              ])
            }),
            0
          )
        : [_vm._v("\n    Задолженности\n    "), _vm._m(0)]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "text-success" }, [
      _c("i", { staticClass: "fas fa-check-circle fa-fw fa-sm mr-1" }),
      _c("b", [_vm._v("отсутствуют")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }