




































































import Vue from 'vue';
import Component from 'vue-class-component';
import get from 'lodash/get';
import isObject from 'lodash/isObject';

@Component({
  components: {},

  props: {
    statements: Object,
  },
})
export default class AppEgrnObjectNestedStatements extends Vue {
  statements: any;
  get = get;
  isObject = isObject;

  get elements() {
    return get(this.statements, 'entity_spatial.spatials_elements', []);
  }
}
