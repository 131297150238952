


























import Vue from 'vue';
import Component from 'vue-class-component';

import AppEgrnObjectReport from 'shared/components/EgrnObject/EgrnObjectReport.vue';
import isEmpty from 'lodash/isEmpty';

@Component({
  popupOptions: {
    name: 'popup_full',
  },

  components: { AppEgrnObjectReport },

  props: {
    landCadInfo: Object,
    objectInfo: Object,
    objectKey: String,
    statements: Object,
    report: Object,
  },
})
export default class EgrnObjectPdfPreview extends Vue {
  landCadInfo: any;
  objectInfo: any;
  objectKey: string;
  statements: any;
  report: any;
  reformaInfo: any = {};
  loading: boolean = true;

  print() {
    window.print();
  }

  fetchRisk() {
    return true;
  }

  async created() {
    if (this.objectInfo.Address) {
      try {
        const data = await this.$api.object.ReformaGkhInfoNew(this.objectInfo.Address);
        if (!isEmpty(data)) {
          this.reformaInfo = data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    this.loading = false;
  }
}
